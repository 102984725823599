// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-authors-jsx": () => import("./../../../src/pages/blog/authors.jsx" /* webpackChunkName: "component---src-pages-blog-authors-jsx" */),
  "component---src-pages-blog-tags-jsx": () => import("./../../../src/pages/blog/tags.jsx" /* webpackChunkName: "component---src-pages-blog-tags-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-notice-jsx": () => import("./../../../src/pages/legal-notice.jsx" /* webpackChunkName: "component---src-pages-legal-notice-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-templates-blog-list-template-jsx": () => import("./../../../src/templates/blog-list-template.jsx" /* webpackChunkName: "component---src-templates-blog-list-template-jsx" */),
  "component---src-templates-blog-post-template-jsx": () => import("./../../../src/templates/blog-post-template.jsx" /* webpackChunkName: "component---src-templates-blog-post-template-jsx" */),
  "component---src-templates-blog-posts-by-author-template-jsx": () => import("./../../../src/templates/blog-posts-by-author-template.jsx" /* webpackChunkName: "component---src-templates-blog-posts-by-author-template-jsx" */),
  "component---src-templates-blog-posts-by-tag-template-jsx": () => import("./../../../src/templates/blog-posts-by-tag-template.jsx" /* webpackChunkName: "component---src-templates-blog-posts-by-tag-template-jsx" */)
}

